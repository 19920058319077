@import "@livingmap/core-ui-v2";

@import "../../scss/newScaleFunctions.scss";
@import "../../scss/common.scss";

$padding: 11.41px 21px;
$font-size: 18px;
$icon-size: 18px;
$icon-margin: 11.5px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      padding: call(get-function($scaleFunction), $padding);
      font-size: call(get-function($scaleFunction), $font-size);

      .icon {
        height: call(get-function($scaleFunction), $icon-size);
        width: call(get-function($scaleFunction), $icon-size);
      }

      .leftIcon {
        margin-right: call(get-function($scaleFunction), $icon-margin);
      }

      .rightIcon {
        margin-left: call(get-function($scaleFunction), $icon-margin);
      }
    }
  }
}

@mixin common-button-styles {
  &:disabled {
    background: rgba($grey-tone-900, 0.12);
    cursor: auto;
    color: $grey-tone-500;
    pointer-events: none;
  }

  &.rounded {
    border-radius: 100px;
  }

  &.outlined {
    background: transparent;

    &:disabled {
      color: $grey-tone-500;
      cursor: auto;
      pointer-events: none;
      background: transparent;
    }
  }
}

@mixin buildButtonColors($bg, $color, $active, $light: true) {
  background: $bg;
  color: $color;

  &.outlined {
    @if $light {
      border: 2px solid $grey-tone-300;
    } @else {
      border: 2px solid $grey-tone-700;
    }

    color: $bg;

    &:active {
      background: $active;
    }

    &:disabled {
      border: 2px solid $grey-tone-500;
    }
  }
}

.button {
  color: $white-tone;
  font-style: normal;
  line-height: 140%;
  letter-spacing: 0.18px;
  font-family: "IBM Plex Sans";
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  border-radius: 22px;
  font-weight: 500;

  // Light Theme
  &.light {
    &.blue {
      @include buildButtonColors(
        $blue-tone-600,
        $white-tone,
        rgba($blue-tone-600, 0.08)
      );
    }

    &.black {
      @include buildButtonColors(
        $grey-tone-900,
        $white-tone,
        rgba($grey-tone-900, 0.04)
      );
    }

    &.white {
      @include buildButtonColors(
        $white-tone,
        $grey-tone-800,
        rgba($white-tone, 0.04)
      );
    }

    @include common-button-styles;
  }

  // Dark Theme
  &.dark {
    &.blue {
      @include buildButtonColors(
        $blue-tone-200,
        $grey-tone-800,
        rgba($blue-tone-200, 0.16),
        false
      );
    }

    &.black {
      @include buildButtonColors(
        $grey-tone-800,
        $grey-tone-050,
        rgba($white-tone, 0.08),
        false
      );
    }

    &.white {
      @include buildButtonColors(
        $grey-tone-050,
        $grey-tone-800,
        rgba($white-tone, 0.08),
        false
      );
    }

    @include common-button-styles;
  }

  @include buildSizes();
}

@include scale-for-4k-media-query {
  .button {
    @include buildSizes($doubled-sizes);
  }
}
