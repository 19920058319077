@import "@livingmap/core-ui-v2";

@import "../../../../scss/common.scss";
@import "../../../../scss/newScaleFunctions.scss";

$container-width: 620px;
$container-padding: 20px;
$border-radius: 24px;
$gradient-height: 60px;
$scroll-max-height: 480px;

$search-result-bottom-padding: 16px;

$no-results-title-size: 18px;
$no-results-paragraph-size: 14px;
$no-results-title-margin-bottom: 4px;
$no-results-container-margin-bottom: 4px;
$no-results-padding: 20px 20px 24px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      border-radius: call(get-function($scaleFunction), $border-radius);
      width: call(get-function($scaleFunction), $container-width);

      &::after {
        border-radius: call(
          get-function($scaleFunction),
          0 0 $border-radius $border-radius
        );
        height: call(get-function($scaleFunction), $gradient-height);
      }

      .scrollContainer {
        max-height: call(get-function($scaleFunction), $scroll-max-height);
        padding: call(get-function($scaleFunction), $container-padding);

        .listContainer {
          .searchResult:not(:last-child) {
            padding-bottom: call(
              get-function($scaleFunction),
              $search-result-bottom-padding
            );
          }
        }
      }

      .noResultsContainer {
        margin-bottom: call(
          get-function($scaleFunction),
          $no-results-container-margin-bottom
        );
        padding: call(get-function($scaleFunction), $no-results-padding);

        .title {
          font-size: call(get-function($scaleFunction), $no-results-title-size);
          margin-bottom: call(
            get-function($scaleFunction),
            $no-results-title-margin-bottom
          );
        }

        .paragraph {
          font-size: call(
            get-function($scaleFunction),
            $no-results-paragraph-size
          );
        }
      }
    }
  }
}

.container {
  box-shadow:
    0px 0px 8px 0px rgba($black-tone, 0.12),
    0px 8px 16px 8px rgba($black-tone, 0.04);
  font-family: "IBM Plex Sans";
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    pointer-events: none;
  }

  .scrollContainer {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    .listContainer {
      margin: 0;
      padding: 0;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .noResultsContainer {
    text-align: left;

    .title {
      @include font-title-medium;
    }

    .paragraph {
      @include font-body-medium;
    }
  }

  &.light {
    background: $white-tone;

    &::after {
      background: linear-gradient(
        180deg,
        rgba($white-tone, 0) 0%,
        rgba($white-tone, 0.7) 100%
      );
    }

    .noResultsContainer {
      .title {
        color: $grey-tone-900;
      }

      .paragraph {
        color: $grey-tone-600;
      }
    }
  }

  &.dark {
    background: $grey-tone-800;

    &::after {
      background: linear-gradient(
        180deg,
        rgba($grey-tone-800, 0) 0%,
        rgba($grey-tone-800, 0.7) 100%
      );
    }

    .noResultsContainer {
      .title {
        color: $grey-tone-050;
      }

      .paragraph {
        color: $grey-tone-400;
      }
    }
  }

  @include buildSizes();
}

@include scale-for-4k-media-query {
  .container {
    @include buildSizes($doubled-sizes);
  }
}
