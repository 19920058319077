@import "@livingmap/core-ui-v2";

@import "../../../../scss/newScaleFunctions.scss";
@import "../../../../scss/common.scss";

$border-radius: 500px;
$border-width: 2px;
$size: 48px;
$active-floor-size: 36px;
$selected-floor-size: 44px;
$poi-dot-size: 8px;
$poi-right: -7px;
$poi-top: 18px;
$label-font-size: 12px;
$label-line-height: 16px;
$label-padding: 12px 8px 8px;
$floor-font-size: 16px;
$floor-line-height: 22px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      border-radius: call(get-function($scaleFunction), $border-radius);

      .label {
        font-size: call(get-function($scaleFunction), $label-font-size);
        line-height: call(get-function($scaleFunction), $label-line-height);
        padding: call(get-function($scaleFunction), $label-padding);
      }

      .floorList {
        width: call(get-function($scaleFunction), $size);

        .listItem {
          height: call(get-function($scaleFunction), $size);
          font-size: call(get-function($scaleFunction), $floor-font-size);
          line-height: call(get-function($scaleFunction), $floor-line-height);
        }

        .userOnFloor {
          &::before {
            width: call(get-function($scaleFunction), $active-floor-size);
            height: call(get-function($scaleFunction), $active-floor-size);
          }
        }

        .poiOnFloor {
          div::after {
            width: call(get-function($scaleFunction), $poi-dot-size);
            height: call(get-function($scaleFunction), $poi-dot-size);
            right: call(get-function($scaleFunction), $poi-right);
            top: call(get-function($scaleFunction), $poi-top);
            border-width: call(get-function($scaleFunction), $border-width);
          }
        }

        .active {
          &::after {
            width: call(get-function($scaleFunction), $selected-floor-size);
            height: call(get-function($scaleFunction), $selected-floor-size);
          }
        }
      }
    }
  }
}

@mixin buildColors(
  $bg,
  $color,
  $active-text-color,
  $current-floor-color,
  $current-floor-bg,
  $selected-floor-bg,
  $poi-border-color
) {
  background: rgba($bg, 0.6);
  border: 1px solid $bg;

  .label {
    color: $color;
  }

  .floorList {
    color: $color;

    .active {
      &::after {
        background-color: $selected-floor-bg;
      }
    }

    .userOnFloor {
      color: $current-floor-color;

      &::before {
        background: $current-floor-bg;
      }
    }

    .active,
    .userOnFloor {
      color: $active-text-color;
    }
  }

  .poiOnFloor {
    div::after {
      border: solid $poi-border-color;
    }
  }
}

.container {
  backdrop-filter: blur(8px);
  flex-direction: column;
  align-items: center;

  .label {
    font-family: "IBM Plex Sans";
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.005em;
    text-align: center;
  }

  .floorList {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    border-radius: $border-radius;

    .listItem {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      text-align: center;
      @include font-title-small;
      position: relative;
    }

    .userOnFloor {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;

      &::before {
        content: "";
        position: absolute;
        border-radius: 100%;
        z-index: -1;
      }
    }

    .poiOnFloor {
      div::after {
        content: "";
        position: absolute;
        border-radius: 100%;
        background: #f5642d;
        z-index: 1;
      }
    }

    .active {
      &::after {
        content: "";
        position: absolute;
        border-radius: 100%;
        z-index: -2;
      }
    }
  }

  &.light {
    box-shadow:
      0px 0px 8px 0px rgba($black-tone, 0.04),
      0px 8px 16px 8px rgba($black-tone, 0.04);

    @include buildColors(
      $white-tone,
      $grey-tone-900,
      $white-tone,
      $grey-tone-900,
      $blue-tone-600,
      $grey-tone-900,
      $white-tone
    );
  }

  &.dark {
    @include buildColors(
      $grey-tone-800,
      $grey-tone-050,
      $grey-tone-800,
      $grey-tone-800,
      $blue-tone-200,
      $grey-tone-050,
      $grey-tone-800
    );
  }

  @include buildSizes();
}

@include scale-for-4k-media-query {
  .container {
    @include buildSizes($doubled-sizes);
  }
}
