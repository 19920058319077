@import "@livingmap/core-ui-v2";

@import "../../../../scss/newScaleFunctions.scss";
@import "../../../../scss/common.scss";

$continue-btn-margin: 24px 0 12px;
$countdown-container-width: 100px;
$countdown-font-size: 44px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    .#{$component-size} {
      &.continueButton {
        margin: call(get-function($scaleFunction), $continue-btn-margin);
      }

      &.countDownTextContainer {
        width: call(get-function($scaleFunction), $countdown-container-width);
        height: call(get-function($scaleFunction), $countdown-container-width);

        .countDownText {
          font-size: call(get-function($scaleFunction), $countdown-font-size);
        }
      }
    }
  }
}

@mixin buildColors($bg, $color, $svg-color) {
  &.countDownTextContainer {
    background-color: $bg;

    .countDownText {
      color: $color;
    }
  }

  &.recentreButton {
    svg {
      color: $svg-color;
    }
  }
}

.countDownTextContainer {
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .countDownText {
    text-align: center;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: 0.44px;
  }
}

.dark {
  @include buildColors(rgba($white-tone, 0.16), $grey-tone-050, $blue-tone-200);
}

.light {
  @include buildColors(
    rgba($grey-tone-900, 0.08),
    $grey-tone-900,
    $blue-tone-600
  );
}

@include buildSizes();

@include scale-for-4k-media-query {
  @include buildSizes($doubled-sizes);
}
