@import "@livingmap/core-ui-v2";

@import "../../../../scss/newScaleFunctions.scss";
@import "../../../../scss/common.scss";

$info-margin: 16px;
$info-max-width: 390px;

$name-font-size: 16px;

$description-font-size: 14px;

$icon-container-size: 48px;
$icon-size: 16px;

$line-height-lg: 22px;
$line-height-md: 20px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      .content {
        .info {
          max-width: call(get-function($scaleFunction), $info-max-width);
          margin-right: call(get-function($scaleFunction), $info-margin);

          .name {
            font-size: call(get-function($scaleFunction), $name-font-size);
            line-height: call(get-function($scaleFunction), $line-height-lg);
          }

          .description {
            font-size: call(
              get-function($scaleFunction),
              $description-font-size
            );
            line-height: call(get-function($scaleFunction), $line-height-md);
          }
        }

        .iconContainer {
          width: call(get-function($scaleFunction), $icon-container-size);
          height: call(get-function($scaleFunction), $icon-container-size);

          .icon {
            width: call(get-function($scaleFunction), $icon-size);
          }
        }
      }
    }
  }
}

@mixin buildColors($bg, $color, $subText-color, $accent) {
  background-color: $bg;

  .content {
    .info {
      .name {
        color: $color;
      }

      .description {
        color: $subText-color;
      }
    }

    .iconContainer {
      color: $bg;
      background: $subText-color;
    }
  }
}

.container {
  display: flex;
  width: 100%;

  .content {
    display: flex;
    width: 100%;
    justify-content: space-between;

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }

    .info {
      z-index: 1;
      .name {
        @include font-label-large-prominent;

        &.titleSearch span {
          text-decoration: underline;
        }
      }

      .description {
        @include font-label-medium;
      }
    }

    .iconContainer {
      display: flex;
      align-self: flex-start;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
    }
  }

  &.light {
    @include buildColors(
      $white-tone,
      $grey-tone-900,
      $blue-tone-600,
      $grey-tone-600
    );
  }

  &.dark {
    @include buildColors(
      $grey-tone-800,
      $grey-tone-050,
      $blue-tone-200,
      $grey-tone-400
    );
  }

  @include buildSizes();
}

@include scale-for-4k-media-query {
  .container {
    @include buildSizes($doubled-sizes);
  }
}
