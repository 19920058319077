@import "@livingmap/core-ui-v2";

@import "../../scss/newScaleFunctions.scss";
@import "../../scss/common.scss";

$size: 56px;
$svg-size: 21px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      &,
      &::before {
        height: call(get-function($scaleFunction), $size);
        width: call(get-function($scaleFunction), $size);
      }

      svg {
        height: call(get-function($scaleFunction), $svg-size);
        width: call(get-function($scaleFunction), $svg-size);
      }
    }
  }
}

@mixin buildColors($bg, $color, $active-bg, $found-color) {
  @keyframes pulse {
    50% {
      color: rgba($grey-tone-800, 0.04);
    }
  }

  background: $bg;

  svg {
    color: $color;
  }

  &.searching {
    svg {
      animation: pulse 1s ease-in-out infinite;
    }
  }

  &.found {
    svg {
      color: $found-color;
    }
  }

  &:active {
    background: $active-bg;
  }

  &:disabled {
    cursor: auto;

    svg {
      color: $grey-tone-500;
    }
  }

  &::before {
    background: $bg;
  }
}

.container {
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  box-shadow:
    0px 4px 12px 5px rgba(0, 0, 0, 0.05),
    0px 0px 12px 0px rgba(0, 0, 0, 0.15);

  &::before {
    content: "";
    position: absolute;
    border-radius: 100%;
    z-index: -1;
  }

  // Light theme
  &.light {
    @include buildColors(
      $white-tone,
      $grey-tone-900,
      rgba($grey-tone-900, 0.04),
      $blue-tone-600
    );
  }

  // Dark theme
  &.dark {
    @include buildColors(
      $grey-tone-800,
      $grey-tone-050,
      rgba($white-tone, 0.12),
      $blue-tone-200
    );
  }

  @include buildSizes();
}

@include scale-for-4k-media-query {
  .container {
    @include buildSizes($doubled-sizes);
  }
}
