@import "@livingmap/core-ui-v2";

@import "../../../../../../scss/common.scss";
@import "../../../../../../scss/newScaleFunctions.scss";

$size: 44px;
$border-radius: 8px;

$font-size: 20px;
$line-height: 30px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      border-radius: call(get-function($scaleFunction), $border-radius);
      height: call(get-function($scaleFunction), $size);
      width: call(get-function($scaleFunction), $size);
      font-size: call(get-function($scaleFunction), $font-size);
      line-height: call(get-function($scaleFunction), $line-height);

      &.activeKey {
        &::before {
          border-radius: call(get-function($scaleFunction), $border-radius);
        }
      }
    }
  }
}

@mixin buildColors($color, $bg, $iconButtonBg, $activeBg) {
  color: $color;
  background: $bg;

  &.iconButton {
    background: $iconButtonBg;
  }

  &.activeKey {
    &::before {
      background: $activeBg;
    }
  }
}

.key {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  border: none;
  margin: 0;
  padding: 0;
  user-select: none;
  transition:
    background 200ms ease-in-out,
    color 200ms ease-in-out;
  font-weight: 450;
  letter-spacing: 0.1px;

  > * {
    pointer-events: none;
  }

  .verticalAlign {
    line-height: 100%;
    height: 50%;
  }

  &.activeKey {
    transition: none;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &.light {
    @include buildColors(
      $grey-tone-900,
      $white-tone,
      rgba($grey-tone-800, 0.08),
      rgba($grey-tone-900, 0.04)
    );
  }

  &.dark {
    @include buildColors(
      $grey-tone-050,
      rgba($white-tone, 0.12),
      rgba($grey-tone-800, 0.16),
      rgba($white-tone, 0.12)
    );
  }

  @include buildSizes();
}

@include scale-for-4k-media-query {
  .key {
    @include buildSizes($doubled-sizes);
  }
}
