@import "@livingmap/core-ui-v2";

@import "../../scss/common.scss";
@import "../../scss/newScaleFunctions.scss";

$accessible-horizon-line-height: 120px;

$button-bottom: 40px;

.bgContainer {
  background: rgba($grey-tone-800, 0.16);
  backdrop-filter: blur(10px);
  bottom: 0;
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;

  .container {
    display: flex;
    justify-content: center;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 3;
    position: fixed;

    &.accessibleHeight {
      bottom: $accessible-horizon-line-height;
    }

    .button {
      position: fixed;
      z-index: 3;
      bottom: $button-bottom;
    }
  }
}

@include scale-for-4k-media-query {
  .bgContainer {
    .container {
      &.accessibleHeight {
        bottom: scaleMediumDouble($accessible-horizon-line-height);
      }

      .button {
        bottom: scaleMediumDouble($button-bottom);
      }
    }
  }
}
