@import "@livingmap/core-ui-v2";

@import "../../scss/common.scss";
@import "../../scss/newScaleFunctions.scss";

$container-border-radius: 12px;
$container-height: 200px;
$container-width: 400px;

$info-container-width: 296px;
$info-container-border-radius: 12px;
$info-margin: 16px;
$info-max-width: 390px;

$name-font-size: 16px;

$description-font-size: 14px;

$text-margin-top: 2px;

$separator-margin: 0 4px;

$distance-font-size: 16px;
$distance-level-font-size: 14px;
$distance-icon-margin-top: 2px;

$line-height-lg: 22px;
$line-height-md: 20px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      .content {
        .info {
          max-width: call(get-function($scaleFunction), $info-max-width);
          margin-right: call(get-function($scaleFunction), $info-margin);

          .name {
            font-size: call(get-function($scaleFunction), $name-font-size);
            line-height: call(get-function($scaleFunction), $line-height-lg);
          }

          .description {
            p {
              font-size: call(
                get-function($scaleFunction),
                $description-font-size
              );
              margin-top: call(get-function($scaleFunction), $text-margin-top);
              line-height: call(get-function($scaleFunction), $line-height-md);
            }
          }

          .address {
            .separator {
              margin: call(get-function($scaleFunction), $separator-margin);
            }
          }
        }

        .distanceInfo {
          margin-top: call(
            get-function($scaleFunction),
            $distance-icon-margin-top
          );

          .distance {
            font-size: call(get-function($scaleFunction), $distance-font-size);
            line-height: call(get-function($scaleFunction), $line-height-lg);
          }

          .floor {
            font-size: call(
              get-function($scaleFunction),
              $distance-level-font-size
            );
            line-height: call(get-function($scaleFunction), $line-height-md);
            margin: call(get-function($scaleFunction), $text-margin-top 0);
          }
        }
      }
    }
  }
}

@mixin buildColors($bg, $color, $subText-color, $accent, $closed) {
  background-color: $bg;

  .content {
    .info {
      .name {
        color: $color;
      }

      .description {
        color: $subText-color;
      }

      .openingTimes {
        span {
          color: $color;
        }

        &.closed {
          span {
            color: $closed;
          }
        }

        &.tempClosed {
          color: $closed;
        }
      }
    }

    .distanceInfo {
      color: $accent;
    }
  }
}

.container {
  display: flex;
  width: 100%;

  .content {
    display: flex;
    width: 100%;
    justify-content: space-between;

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }

    .info {
      z-index: 1;
      .name {
        @include font-label-large-prominent;

        &.titleSearch span {
          text-decoration: underline;
        }
      }

      .description {
        .subtitle {
          @include font-body-medium;
        }

        .address,
        .openingTimes {
          @include font-body-medium;
        }
      }
    }

    .distanceInfo {
      color: $grey-tone-050;
      text-align: right;
      z-index: 1;

      .distance {
        @include font-label-large;
      }

      .floor {
        @include font-label-medium;
      }

      .icon {
        &.floorUp {
          transform: rotate(-135deg);
        }

        &.floorDown {
          transform: rotate(-45deg);
        }
      }
    }
  }

  &.light {
    @include buildColors(
      $white-tone,
      $grey-tone-900,
      $grey-tone-600,
      $blue-tone-600,
      $red-tone-600
    );
  }

  &.dark {
    @include buildColors(
      $grey-tone-800,
      $grey-tone-050,
      $grey-tone-400,
      $blue-tone-200,
      $red-tone-200
    );
  }

  @include buildSizes();
}

@include scale-for-4k-media-query {
  .container {
    @include buildSizes($doubled-sizes);
  }
}
