@import "@livingmap/core-ui-v2";

@import "../../../../scss/newScaleFunctions.scss";
@import "../../../../scss/common.scss";

$btn-padding: 16px 109px;
$btn-text-size: 22px;
$btn-svg-size: 24px;
$btn-svg-margin-right: 12px;

$btn-saved-term-padding: 16px 154px 16px 20px;

$clear-btn-margin: 10px 10px 10px 0;
$clear-btn-top-pos: 9px;

$ani-circle-size: 160px;
$ani-blur: 80px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      &.savedTerm {
        .button {
          padding: call(get-function($scaleFunction), $btn-saved-term-padding);
        }

        .clearButton {
          margin: call(get-function($scaleFunction), $clear-btn-margin);
        }
      }

      .button {
        padding: call(get-function($scaleFunction), $btn-padding);

        .text {
          font-size: call(get-function($scaleFunction), $btn-text-size);
        }

        .svg {
          margin-right: call(
            get-function($scaleFunction),
            $btn-svg-margin-right
          );
          width: call(get-function($scaleFunction), $btn-svg-size);
          height: call(get-function($scaleFunction), $btn-svg-size);
        }

        .animationContainer {
          .circle {
            width: call(get-function($scaleFunction), $ani-circle-size);
            height: call(get-function($scaleFunction), $ani-circle-size);
            filter: blur(call(get-function($scaleFunction), $ani-blur));

            &.red1 {
              animation: red1 11s linear infinite;
            }

            &.purple1 {
              animation: purple1 11s linear infinite;
            }

            &.purple2 {
              animation: purple2 11s linear infinite;
            }

            &.white1 {
              animation: white1 11s linear infinite;
            }
          }
        }

        @keyframes red1 {
          0%,
          30% {
            top: call(get-function($scaleFunction), -80px);
            left: call(get-function($scaleFunction), -360px);
          }

          46% {
            top: call(get-function($scaleFunction), -104px);
            left: call(get-function($scaleFunction), 500px);
            opacity: 0.8;
          }

          47%,
          63% {
            opacity: 0;
          }

          64% {
            opacity: 0.8;
            top: call(get-function($scaleFunction), -52px);
            left: call(get-function($scaleFunction), -280px);
          }

          100% {
            top: call(get-function($scaleFunction), -52px);
            left: call(get-function($scaleFunction), 500px);
          }
        }

        @keyframes purple1 {
          0%,
          30% {
            top: 0;
            left: call(get-function($scaleFunction), -280px);
          }

          46% {
            top: call(get-function($scaleFunction), -184px);
            left: call(get-function($scaleFunction), 580px);

            opacity: 0.8;
          }

          47%,
          63% {
            opacity: 0;
          }

          64% {
            opacity: 0.8;
            top: call(get-function($scaleFunction), -52px);
            left: call(get-function($scaleFunction), -440px);
          }

          100% {
            top: call(get-function($scaleFunction), -52px);
            left: call(get-function($scaleFunction), 500px);
          }
        }

        @keyframes purple2 {
          0%,
          30% {
            opacity: 0.8;
            top: call(get-function($scaleFunction), 576px);
            left: call(get-function($scaleFunction), -440px);
          }

          46% {
            top: call(get-function($scaleFunction), -344px);
            left: call(get-function($scaleFunction), 500px);
            opacity: 0.8;
          }

          47%,
          100% {
            opacity: 0;
          }
        }

        @keyframes white1 {
          0%,
          30% {
            opacity: 0.8;
            top: call(get-function($scaleFunction), 96px);
            left: call(get-function($scaleFunction), -720px);
          }

          46% {
            top: call(get-function($scaleFunction), -304px);
            left: call(get-function($scaleFunction), 780px);
            opacity: 0.8;
          }

          47%,
          100% {
            opacity: 0;
          }
        }
      }
    }
  }
}

@mixin buildColors(
  $color,
  $bg,
  $ani-circle-color-1,
  $ani-circle-color-2,
  $ani-circle-color-3
) {
  .button {
    background-color: $bg;
    color: $color;

    .animationContainer {
      .circle {
        &.red1 {
          background: $ani-circle-color-1;
        }

        &.purple1,
        &.purple2 {
          background: $ani-circle-color-2;
        }

        &.white1 {
          background: $ani-circle-color-3;
        }
      }
    }
  }
}

.container {
  position: relative;

  .clearButton {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .button {
    border-radius: 99px;
    box-shadow:
      0px 0px 8px 0px rgba($black-tone, 0.04),
      0px 12px 32px 0px rgba($black-tone, 0.12);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    .text {
      @include font-label-large;
      z-index: 1;
    }

    .animationContainer {
      width: 100%;
      height: 100%;
      position: absolute;

      &.interacted {
        animation: gradualBlur 3s forwards;
      }

      .circle {
        position: absolute;
        opacity: 0.4;
        border-radius: 100%;
      }
    }
  }

  &.light {
    @include buildColors(
      $grey-tone-900,
      $white-tone,
      $red-tone-600,
      $purple-tone-600,
      $white-tone
    );
  }

  &.dark {
    @include buildColors(
      $grey-tone-050,
      $grey-tone-800,
      $red-tone-200,
      $purple-tone-200,
      $white-tone
    );
  }

  @include buildSizes();
}

@include scale-for-4k-media-query {
  .container {
    @include buildSizes($doubled-sizes);
  }
}

@keyframes gradualBlur {
  from {
    filter: blur(0px);
  }

  to {
    filter: blur(999px);
  }
}
