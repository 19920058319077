@import "@livingmap/core-ui-v2";

@import "../../scss/newScaleFunctions.scss";
@import "../../scss/common.scss";

$container-border-radius: 24px;
$font-size: 14px;
$margin-left: 8px;
$padding: 10px 21px 10px 15px;

$slider-width: 52px;
$slider-height: 28px;
$slider-toggle-size: 20px;
$slider-toggle-expanded-width: 28px;
$slider-translateX: 22px;
$slider-radius: 25px;
$slider-left-position: 5px;
$slider-bottom-position: 4px;

$svg-width: 9.5px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    .#{$component-size} {
      &.container {
        font-size: call(get-function($scaleFunction), $font-size);
        border-radius: call(
          get-function($scaleFunction),
          $container-border-radius
        );
        padding: call(get-function($scaleFunction), $padding);

        .textLabel {
          margin-left: call(get-function($scaleFunction), $margin-left);
        }

        .label {
          width: call(get-function($scaleFunction), $slider-width);
          height: call(get-function($scaleFunction), $slider-height);

          .input {
            &:checked + .slider .sliderToggle {
              animation: #{$component-size}Checked 250ms linear forwards;
            }

            &:not(:checked) + .slider .sliderToggle {
              animation: #{$component-size}Unchecked 250ms linear forwards;
            }
          }

          .slider {
            border-radius: call(get-function($scaleFunction), $slider-radius);

            &Toggle {
              height: call(get-function($scaleFunction), $slider-toggle-size);
              width: call(get-function($scaleFunction), $slider-toggle-size);
              left: call(get-function($scaleFunction), $slider-left-position);
              bottom: call(
                get-function($scaleFunction),
                $slider-bottom-position
              );
              border-radius: call(get-function($scaleFunction), $slider-radius);

              svg {
                width: call(get-function($scaleFunction), $svg-width);
              }
            }
          }
        }

        @keyframes #{$component-size}Checked {
          0% {
            width: call(get-function($scaleFunction), $slider-toggle-size);
          }
          50% {
            width: call(
              get-function($scaleFunction),
              $slider-toggle-expanded-width
            );
          }
          100% {
            width: call(get-function($scaleFunction), $slider-toggle-size);
            transform: translateX(
              call(get-function($scaleFunction), $slider-translateX)
            );
          }
        }

        @keyframes #{$component-size}Unchecked {
          0% {
            width: call(get-function($scaleFunction), $slider-toggle-size);
            transform: translateX(
              call(get-function($scaleFunction), $slider-translateX)
            );
          }
          50% {
            width: call(
              get-function($scaleFunction),
              $slider-toggle-expanded-width
            );
          }
          100% {
            width: call(get-function($scaleFunction), $slider-toggle-size);
          }
        }
      }
    }
  }
}

@mixin buildColors(
  $color,
  $bg,
  $slider-bg,
  $active-slider-bg,
  $disabled-slider-bg,
  $disabled-slider
) {
  &.container {
    color: $color;
    background: $bg;

    .input {
      &:checked + .slider {
        background: $active-slider-bg;

        svg {
          color: $active-slider-bg;
        }
      }

      &:disabled + .slider {
        background: $disabled-slider-bg;

        .sliderToggle {
          background: $disabled-slider;

          svg {
            color: $disabled-slider-bg;
          }
        }
      }
    }

    .slider {
      background: $slider-bg;

      &Toggle {
        background: $bg;
      }
    }
  }
}

@include buildSizes();

.light {
  @include buildColors(
    $grey-tone-900,
    $white-tone,
    $grey-tone-300,
    $grey-tone-900,
    $grey-tone-200,
    $grey-tone-400
  );
}

.dark {
  @include buildColors(
    $grey-tone-050,
    $grey-tone-800,
    $grey-tone-500,
    $blue-tone-200,
    $grey-tone-600,
    $grey-tone-500
  );
}

.container {
  @include font-label-medium;
  display: flex;
  align-items: center;
  width: max-content;
  box-shadow:
    0px 8px 16px 8px rgba($black-tone, 0.04),
    0px 0px 8px 0px rgba($black-tone, 0.12);

  cursor: pointer;

  .label {
    position: relative;
    display: inline-block;

    .input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .slider .sliderToggle {
        svg {
          animation: svg 250ms linear;
        }
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;

      &Toggle {
        position: absolute;

        svg {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          position: absolute;
        }
      }
    }
  }
}

@keyframes svg {
  to {
    left: 60%;
  }
}

@include scale-for-4k-media-query {
  @include buildSizes($doubled-sizes);
}
