@import "@livingmap/core-ui-v2";

@import "../../../../scss/common.scss";
@import "../../../../scss/newScaleFunctions.scss";

$image-border-radius: 8px;
$image-margin-right: 16px;
$image-size: 88px;
$image-border-width: 1px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      border-radius: call(get-function($scaleFunction), $image-border-radius);
      margin-right: call(get-function($scaleFunction), $image-margin-right);
      min-width: call(get-function($scaleFunction), $image-size);
      max-width: call(get-function($scaleFunction), $image-size);
      height: call(get-function($scaleFunction), $image-size);
      border-width: call(get-function($scaleFunction), $image-border-width);

      .image {
        width: call(get-function($scaleFunction), $image-size);
        height: call(get-function($scaleFunction), $image-size);
      }
    }
  }
}

@mixin buildColors($bg) {
  background: $bg;
  border-color: $bg;
}

.container {
  overflow: hidden;
  border-style: solid;

  .image {
    object-fit: cover;
  }

  &.light {
    @include buildColors($grey-tone-100);
  }

  &.dark {
    @include buildColors($grey-tone-700);
  }

  @include buildSizes();
}

@include scale-for-4k-media-query {
  .container {
    @include buildSizes($doubled-sizes);
  }
}
