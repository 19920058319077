@import "@livingmap/core-ui-v2";

@import "../../../../scss/newScaleFunctions.scss";
@import "../../../../scss/common.scss";

$container-border-radius: 24px;
$container-height: 250px;
$container-width: 400px;

$qr-code-container-width: 100px;
$qr-code-container-margin: 20px;
$qr-code-padding: 4px;
$qr-code-text-margin: 4px 0 12px 4px;
$qr-code-font-size: 12px;

$btn-size: 48px;
$btn-position: 12px;

$label-font-size: 12px;
$label-font-height: 16px;
$label-padding: 3.5px 8px 4.5px;
$label-border-radius: 4px;
$label-position: 20px;

$line-height-sm: 16px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    .#{$component-size} {
      &.container {
        height: call(get-function($scaleFunction), $container-height);
        border-radius: call(
          get-function($scaleFunction),
          $container-border-radius
        );

        .qrCodeContainer {
          width: call(
            get-function($scaleFunction),
            $qr-code-container-width + ($qr-code-container-margin * 2)
          );

          .qrCodeContent {
            width: call(get-function($scaleFunction), $qr-code-container-width);
            margin: call(
              get-function($scaleFunction),
              $qr-code-container-margin
            );

            p {
              margin: call(get-function($scaleFunction), $qr-code-text-margin);
              font-size: call(get-function($scaleFunction), $qr-code-font-size);
              line-height: call(get-function($scaleFunction), $line-height-sm);
            }

            .qrCode {
              padding: call(get-function($scaleFunction), $qr-code-padding);
            }
          }

          &.hide {
            width: 0;
          }
        }

        .infoContainer {
          border-radius: call(
            get-function($scaleFunction),
            $container-border-radius
          );

          .image {
            border-radius: call(
              get-function($scaleFunction),
              $container-border-radius
            );
          }

          .closeBtn {
            width: call(get-function($scaleFunction), $btn-size);
            height: call(get-function($scaleFunction), $btn-size);
            right: call(get-function($scaleFunction), $btn-position);
            top: call(get-function($scaleFunction), $btn-position);
          }

          .label {
            font-size: call(get-function($scaleFunction), $label-font-size);
            line-height: call(get-function($scaleFunction), $label-font-height);
            padding: call(get-function($scaleFunction), $label-padding);
            border-radius: call(
              get-function($scaleFunction),
              $label-border-radius
            );
            right: call(get-function($scaleFunction), $label-position);
            top: call(get-function($scaleFunction), $label-position);
          }
        }
      }
    }
  }
}

@mixin buildColors(
  $bg,
  $info-bg,
  $qr-text-color,
  $btn-bg,
  $btn-color,
  $label,
  $label-bg
) {
  &.container {
    background: $bg;

    .qrCodeContainer {
      color: $qr-text-color;
    }

    .infoContainer {
      background: $info-bg;
    }

    .closeBtn {
      color: $btn-color;
      background: $btn-bg;
    }

    .label {
      color: $label;
      background: $label-bg;
    }
  }
}

@include buildSizes();

.light {
  @include buildColors(
    $white-tone,
    $grey-tone-100,
    $grey-tone-900,
    $white-tone,
    $grey-tone-900,
    $white-tone,
    $blue-tone-600
  );
}

.dark {
  @include buildColors(
    $grey-tone-800,
    #525252,
    $grey-tone-050,
    $grey-tone-700,
    $grey-tone-050,
    $grey-tone-800,
    $blue-tone-200
  );
}

.container {
  display: flex;
  justify-content: space-between;
  box-shadow:
    0px 0px 8px 0px rgba($black-tone, 0.04),
    0px 8px 16px 8px rgba($black-tone, 0.04);

  p {
    margin: 0;
  }
  .qrCodeContainer {
    transition: width 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .qrCodeContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      // TODO: need to be updated when new typography styles added
      /* label/tiny (9) */
      font-family: "IBM Plex Sans";
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.06px;

      .qrCode {
        background: $white-tone;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .infoContainer {
    position: relative;
    display: flex;
    position: relative;
    flex: 1;
    text-shadow: 0px 0px 5px rgba($black-tone, 0.1);

    .image {
      object-fit: cover;
      height: 100%;
      width: 100%;
      position: absolute;
    }

    .closeBtn {
      position: absolute;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
    }

    .label {
      @include font-label-small;
      position: absolute;
      text-transform: uppercase;
    }
  }
}

@include scale-for-4k-media-query {
  @include buildSizes($doubled-sizes);
}
