@import-normalize; /* bring in normalize.css styles */

@import "@livingmap/hippo";
@import "scss/common.scss";
@import url("./fonts/fonts.css");

:root {
  --root-font-size: 16px;
}

html {
  font-size: 16px;
}

* {
  box-sizing: border-box;
  user-select: none;
  cursor: none !important;
}

*:focus,
*:active {
  outline: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mapboxgl-control-container .mapboxgl-ctrl:not(.mapboxgl-ctrl-attrib) {
  display: none !important;
}

.mapboxgl-control-container .mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  font-size: 12px;
  padding: 0 5px;
}

.mapboxgl-control-container .mapboxgl-ctrl.mapboxgl-ctrl-attrib a {
  pointer-events: none;
}

.mapboxgl-control-container
  .mapboxgl-ctrl.mapboxgl-ctrl-attrib
  .mapboxgl-ctrl-attrib-inner {
  line-height: 1.5;
}

.mapboxgl-popup {
  max-width: none !important;
}

.mapboxgl-popup .mapboxgl-popup-content {
  background: none;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
}

.mapboxgl-popup-tip {
  display: none;
}

.asset-information {
  z-index: 1;
}

@include scale-for-4k-media-query {
  .mapboxgl-control-container .mapboxgl-ctrl.mapboxgl-ctrl-attrib {
    font-size: 24px;
    padding: 0 10px;
  }

  html {
    font-size: 32px;
  }

  :root {
    --root-font-size: 32px;
  }
}
