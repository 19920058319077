@import "@livingmap/core-ui-v2";

@import "../../../../scss/newScaleFunctions.scss";
@import "../../../../scss/common.scss";

$size: 56px;
$svg-compass-border-height: 56px;
$svg-compass-text-height: 14px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      width: call(get-function($scaleFunction), $size);
      height: call(get-function($scaleFunction), $size);

      .compassBorderSvg {
        width: call(get-function($scaleFunction), $svg-compass-border-height);
      }

      .compassTextSvg {
        height: call(get-function($scaleFunction), $svg-compass-text-height);
      }
    }
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  box-shadow:
    0px 0px 8px 0px rgba($black-tone, 0.04),
    0px 8px 16px 8px rgba($black-tone, 0.04);

  .compassTextSvg {
    position: absolute;
  }

  &.dark {
    background: $grey-tone-800;
    color: $grey-tone-050;
  }

  &.light {
    background: $white-tone;
    color: $grey-tone-900;
  }

  @include buildSizes();
}

@include scale-for-4k-media-query {
  .container {
    @include buildSizes($doubled-sizes);
  }
}
