@import "@livingmap/core-ui-v2";

@import "../../../../scss/newScaleFunctions.scss";
@import "../../../../scss/common.scss";

$container-size: 620px;
$font-size: 18px;
$font-line-height: 24px;
$border-radius: 24px;
$input-padding: 15px 56px 17px;

$clear-icon-size: 14px;
$clear-icon-top: 21px;
$clear-icon-right: 29px;

$search-icon-size: 18px;
$search-icon-top: 19px;
$search-icon-right: 27px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      width: call(get-function($scaleFunction), $container-size);
      border-radius: call(get-function($scaleFunction), $border-radius);

      .contentWrapper {
        border-radius: call(get-function($scaleFunction), $border-radius);
      }

      .input {
        padding: call(get-function($scaleFunction), $input-padding);
        font-size: call(get-function($scaleFunction), $font-size);
        border-radius: call(
          get-function($scaleFunction),
          $border-radius $border-radius 0 0
        );
        line-height: call(get-function($scaleFunction), $font-line-height);
      }

      .closeIcon {
        top: call(get-function($scaleFunction), $clear-icon-top);
        right: call(get-function($scaleFunction), $clear-icon-right);

        > svg {
          width: call(get-function($scaleFunction), $clear-icon-size);
          height: call(get-function($scaleFunction), $clear-icon-size);
        }
      }

      .searchIcon {
        top: call(get-function($scaleFunction), $search-icon-top);
        left: call(get-function($scaleFunction), $search-icon-right);

        width: call(get-function($scaleFunction), $search-icon-size);
        height: call(get-function($scaleFunction), $search-icon-size);
      }
    }
  }
}

@mixin buildColors(
  $input-color,
  $input-bg,
  $placeholder-color,
  $search-icon-color,
  $close-icon-color
) {
  .input {
    background: $input-bg;
    color: $input-color;

    &::placeholder {
      color: $placeholder-color;
    }
  }

  .closeIcon {
    background: $input-bg;
    color: $input-color;
  }

  .searchIcon {
    color: $search-icon-color;
  }
}

.container {
  position: relative;
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  max-width: 100%;
  margin: 0 auto;
  box-shadow:
    0px 0px 8px 0px rgba($black-tone, 0.12),
    0px 8px 16px 8px rgba($black-tone, 0.04);

  .input {
    font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
    width: 100%;
    outline: none;
    border: none;
    text-align: center;
    pointer-events: none;
    font-weight: 500;
    letter-spacing: 0.09px;
  }

  .closeIcon,
  .searchIcon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 0;
  }

  &.light {
    @include buildColors(
      $grey-tone-900,
      $white-tone,
      $grey-tone-500,
      $grey-tone-600,
      $grey-tone-900
    );
  }

  &.dark {
    @include buildColors(
      $grey-tone-050,
      $grey-tone-800,
      $grey-tone-500,
      $grey-tone-400,
      $white-tone
    );
  }

  @include buildSizes();
}

@include scale-for-4k-media-query {
  .container {
    @include buildSizes($doubled-sizes);
  }
}
