@import "@livingmap/core-ui-v2";

@import "../../../../../../scss/newScaleFunctions.scss";
@import "../../../../../../scss/common.scss";

$border-radius: 24px;
$container-padding: 16px;
$key-gap: 7px;

$backspace-width: 60px;
$spacebar-width: 252px;

$arrow-key-icon-size: 16px;
$backspace-key-icon-size: 24px;

$row-two-margin: 0 38px;
$row-three-margin: 0 64px;
$row-four-margin: 0 116px;
$row-five-margin-left: 168px;

$arrow-key-margin-left: 72px;

$blur: 40px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      gap: call(get-function($scaleFunction), $key-gap);
      padding: call(get-function($scaleFunction), $container-padding);
      border-radius: call(
        get-function($scaleFunction),
        0 0 $border-radius $border-radius
      );

      .backdrop {
        border-radius: call(
          get-function($scaleFunction),
          0 0 $border-radius $border-radius
        );
        backdrop-filter: blur(call(get-function($scaleFunction), $blur));
      }

      .rowOne {
        .backspaceKey {
          width: call(get-function($scaleFunction), $backspace-width);
          margin-left: call(get-function($scaleFunction), $key-gap);

          > svg {
            width: call(get-function($scaleFunction), $backspace-key-icon-size);
          }
        }
      }

      .rowTwo {
        margin: call(get-function($scaleFunction), $row-two-margin);
      }

      .rowThree {
        margin: call(get-function($scaleFunction), $row-three-margin);
      }

      .rowFour {
        margin: call(get-function($scaleFunction), $row-four-margin);
      }

      .rowFive {
        .columnOne {
          width: call(get-function($scaleFunction), $spacebar-width);
          margin-left: call(
            get-function($scaleFunction),
            $row-five-margin-left
          );

          .spaceKey {
            width: call(get-function($scaleFunction), $spacebar-width);
          }
        }

        .columnTwo {
          margin-left: call(
            get-function($scaleFunction),
            $arrow-key-margin-left
          );

          .arrowKey {
            > svg {
              width: call(get-function($scaleFunction), $arrow-key-icon-size);
              height: call(get-function($scaleFunction), $arrow-key-icon-size);
            }
          }
        }
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;

  .row,
  .column {
    display: flex;
    justify-content: space-between;
  }

  .rowFive {
    .columnTwo {
      flex: 1;
    }
  }

  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }

  &.light {
    .backdrop {
      background: rgba($white-tone, 0.6);
    }
  }

  &.dark {
    .backdrop {
      background: rgba($grey-tone-800, 0.6);
    }
  }

  @include buildSizes();
}

@include scale-for-4k-media-query {
  .container {
    @include buildSizes($doubled-sizes);
  }
}
