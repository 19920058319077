@import "@livingmap/core-ui-v2";

@import "../../../../scss/newScaleFunctions.scss";
@import "../../../../scss/common.scss";

$control-size: 116px;
$control-radius: 2.5px;
$icon-width: 14px;
$icon-height: 8.5px;
$icon-bottom: 20px;
$icon-left: 17px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      width: call(get-function($scaleFunction), $control-size);
      height: call(get-function($scaleFunction), $control-size);

      .buttonUp,
      .buttonRight,
      .buttonDown,
      .buttonLeft {
        width: call(get-function($scaleFunction), $control-size);
        height: call(get-function($scaleFunction), $control-size);
        border-radius: call(get-function($scaleFunction), $control-radius);

        .chevron {
          width: call(get-function($scaleFunction), $icon-width);
          height: call(get-function($scaleFunction), $icon-height);
          bottom: call(get-function($scaleFunction), $icon-bottom);
          left: call(get-function($scaleFunction), $icon-left);
        }
      }
    }
  }
}

@mixin buildColors($color, $bg, $active-bg) {
  background: $bg;
  color: $color;
  border: 1px solid $bg;

  .buttonUp,
  .buttonRight,
  .buttonDown,
  .buttonLeft {
    &:active {
      background: $active-bg;
    }
  }
}

.container {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  backdrop-filter: blur(8px);

  .buttonUp,
  .buttonRight,
  .buttonDown,
  .buttonLeft {
    position: absolute;
    top: -50%;
    left: 50%;
    transform-origin: bottom left;
    z-index: 1;
    background: transparent;
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    color: inherit;

    .chevron {
      position: absolute;
      transform: rotate(45deg);
    }
  }

  .buttonUp {
    transform: rotate(-45deg);
  }

  .buttonRight {
    transform: rotate(45deg);
  }

  .buttonDown {
    transform: rotate(135deg);
  }

  .buttonLeft {
    transform: rotate(-135deg);
  }

  &.dark {
    @include buildColors(
      $grey-tone-050,
      rgba($grey-tone-800, 0.2),
      $grey-tone-800
    );
  }

  &.light {
    box-shadow:
      0px 0px 8px 0px rgba($black-tone, 0.04),
      0px 8px 16px 8px rgba($black-tone, 0.04);

    @include buildColors($grey-tone-900, rgba($white-tone, 0.2), $white-tone);
  }

  @include buildSizes();
}

@include scale-for-4k-media-query {
  .container {
    @include buildSizes($doubled-sizes);
  }
}
