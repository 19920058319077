$scale-double-min-width: 2160px;

@mixin scale-for-4k-media-query {
  @media (min-width: $scale-double-min-width) {
    @content;
  }
}

@function px-to-rem($px-values) {
  $root-font-size: 16px;

  $rem-values: ();

  // loops through the list and convert each value to rem
  @each $px in $px-values {
    $rem-values: append($rem-values, $px / $root-font-size * 1rem);
  }

  @return $rem-values;
}
