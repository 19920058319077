@import "@livingmap/core-ui-v2";

@import "../../../../scss/newScaleFunctions.scss";
@import "../../../../scss/common.scss";

$container-height: 48px;
$container-width: 96px;
$container-border-radius: 99px;

$btn-border-radius: 99px;
$btn-padding: 10px;
$btn-size: 48px;
$btn-svg-size: 16px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      &:not(.vertical) {
        height: call(get-function($scaleFunction), $container-height);
        width: call(get-function($scaleFunction), $container-width);
      }

      &.vertical {
        height: call(get-function($scaleFunction), $container-width);
        width: call(get-function($scaleFunction), $container-height);
      }

      .button {
        padding: call(get-function($scaleFunction), $btn-padding);
        height: call(get-function($scaleFunction), $btn-size);
        width: call(get-function($scaleFunction), $btn-size);
      }

      .svg {
        width: call(get-function($scaleFunction), $btn-svg-size);
        height: call(get-function($scaleFunction), $btn-svg-size);
      }
    }
  }
}

@mixin buildColors($color, $bg, $active-bg) {
  background: $bg;
  border: 1px solid $bg;

  .svg {
    color: $color;
  }

  .button {
    &:active {
      background-color: $active-bg;
    }

    &.disabled {
      .svg {
        color: $grey-tone-500;
      }
    }
  }
}

.container {
  display: flex;
  border-radius: $container-border-radius;
  backdrop-filter: blur(8px);

  &.vertical {
    flex-direction: column-reverse;
    align-items: center;
  }

  .button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $btn-border-radius;
  }

  &.dark {
    @include buildColors(
      $grey-tone-050,
      rgba($grey-tone-800, 0.2),
      $grey-tone-800
    );
  }

  &.light {
    box-shadow:
      0px 0px 8px 0px rgba($black-tone, 0.04),
      0px 8px 16px 8px rgba($black-tone, 0.04);

    @include buildColors($grey-tone-900, rgba($white-tone, 0.2), $white-tone);
  }

  @include buildSizes();
}

@include scale-for-4k-media-query {
  .container {
    @include buildSizes($doubled-sizes);
  }
}
