@import "@livingmap/core-ui-v2";

@import "../../scss/newScaleFunctions.scss";
@import "../../scss/common.scss";

$container-border-radius: 24px;
$font-size: 14px;
$margin-left: 8.5px;
$padding: 10px 21px 10px 15px;
$icon-size: 20px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    .#{$component-size} {
      &.container {
        font-size: call(get-function($scaleFunction), $font-size);
        border-radius: call(
          get-function($scaleFunction),
          $container-border-radius
        );
        padding: call(get-function($scaleFunction), $padding);

        .icon {
          width: $icon-size;
          height: $icon-size;

          &.error::before {
            width: calc($icon-size / 2);
            height: calc($icon-size / 2);
            left: calc($icon-size / 4);
            top: calc($icon-size / 4);
          }
        }

        .textLabel {
          margin-left: call(get-function($scaleFunction), $margin-left);
        }
      }
    }
  }
}

@mixin buildColors($color, $bg, $check-color, $error-color, $error-bg) {
  &.container {
    color: $color;
    background: $bg;

    .icon {
      &.check {
        svg {
          color: $check-color;
        }
      }

      &.error {
        svg {
          color: $error-color;
        }

        &::before {
          background: $error-bg;
        }
      }
    }
  }
}

@include buildSizes();

.light {
  @include buildColors(
    $grey-tone-900,
    $white-tone,
    $green-tone-600,
    $yellow-tone-600,
    $grey-tone-900
  );
}

.dark {
  @include buildColors(
    $grey-tone-050,
    $grey-tone-800,
    $green-tone-200,
    $yellow-tone-200,
    rgba($grey-tone-800, 0.6)
  );
}

.container {
  @include font-label-medium;
  display: flex;
  align-items: center;
  width: max-content;
  z-index: 1;
  box-shadow:
    0px 8px 16px 8px rgba($black-tone, 0.04),
    0px 0px 8px 0px rgba($black-tone, 0.12);

  .icon {
    border-radius: 100%;
    position: relative;

    &.error::before {
      content: "";
      position: absolute;
      border-radius: 100%;
      z-index: -1;
    }
  }
}

@include scale-for-4k-media-query {
  @include buildSizes($doubled-sizes);
}
