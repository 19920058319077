@import "@livingmap/core-ui-v2";

@import "../../../../scss/common.scss";
@import "../../../../scss/newScaleFunctions.scss";

$swiper-container: 832px;
$swiper-slide: 580px;
$inactive-swiper-slide-border-radius: 24px;
$inactive-translate-x: 409px;
$inactive-translate-z: -130px;
$inactive-scale: 1;
$active-translate-x: 126px;

$swiper-button-size: 48px;
$swiper-button-bottom: 100px;
$swiper-button-x-position: 12px;
$swiper-button-icon-height: 12px;
$swiper-button-icon-width: 7.41px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      .swiperContainer {
        width: call(get-function($scaleFunction), $swiper-container);

        .swiperSlide {
          width: call(get-function($scaleFunction), $swiper-slide);

          &.inactive {
            &::after {
              border-radius: call(
                get-function($scaleFunction),
                $inactive-swiper-slide-border-radius
              );
            }
          }

          &.activeLeft {
            transform: translateX(
              call(get-function($scaleFunction), -$active-translate-x)
            ) !important;
          }

          &.activeRight {
            transform: translateX(
              call(get-function($scaleFunction), $active-translate-x)
            ) !important;
          }

          &.left {
            transform: translateX(
                call(get-function($scaleFunction), $inactive-translate-x)
              )
              translateZ($inactive-translate-z)
              scale(
                call(get-function($scaleFunction), $inactive-scale)
              ) !important;
          }

          &.right {
            transform: translateX(
                call(get-function($scaleFunction), -$inactive-translate-x)
              )
              translateZ($inactive-translate-z)
              scale(
                call(get-function($scaleFunction), $inactive-scale)
              ) !important;
          }
        }

        .swiperButton {
          height: call(get-function($scaleFunction), $swiper-button-size);
          width: call(get-function($scaleFunction), $swiper-button-size);
          bottom: call(get-function($scaleFunction), $swiper-button-bottom);

          svg {
            height: call(
              get-function($scaleFunction),
              $swiper-button-icon-height
            );
            width: call(
              get-function($scaleFunction),
              $swiper-button-icon-width
            );
          }

          &.nextButton {
            right: call(
              get-function($scaleFunction),
              $swiper-button-x-position
            );
          }

          &.prevButton {
            left: call(get-function($scaleFunction), $swiper-button-x-position);
          }
        }
      }
    }
  }
}

.container {
  .swiperContainer {
    position: relative;

    .swiperSlide {
      &.inactive {
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
          background: rgba($black-tone, 0.4);
        }
      }
    }

    button.swiperButton {
      z-index: 1;
      position: absolute;
      background: $white-tone;

      &:disabled {
        display: none;
      }

      &.nextButton {
        right: 0;
      }

      svg {
        position: absolute;
        color: $grey-tone-900;
      }

      &:active {
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
          background: rgba($black-tone, 0.08);
          border-radius: 100%;
        }
      }
    }
  }

  @include buildSizes();
}

@include scale-for-4k-media-query {
  .container {
    @include buildSizes($doubled-sizes);
  }
}
