@import "@livingmap/core-ui-v2";

@import "../../scss/newScaleFunctions.scss";
@import "../../scss/common.scss";

$container-border-radius: 24px;

$info-max-width: 320px;

$background-shadow-width: 380px;

$content-margin: 20px 40px 20px 20px;

$name-font-size: 22px;

$category-font-size: 16px;
$category-margin-top: 8px;

$description-font-size: 14px;

$text-margin-top: 4px;

$separator-margin: 0 4px;

$distance-font-size: 16px;
$distance-border-radius: 8px;
$distance-padding: 8px 12px;
$distance-gap: 8px;

$floor-icon-size: 18px;

$shadow-width: 380px;

$line-height-lg: 30px;
$line-height-md: 22px;
$line-height-sm: 20px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    .#{$component-size} {
      &.container {
        width: call(get-function($scaleFunction), $info-max-width);
        margin: call(get-function($scaleFunction), $content-margin);

        &::before {
          width: call(get-function($scaleFunction), $background-shadow-width);

          border-top-left-radius: call(
            get-function($scaleFunction),
            $container-border-radius
          );
          border-bottom-left-radius: call(
            get-function($scaleFunction),
            $container-border-radius
          );
        }

        .info {
          max-width: call(get-function($scaleFunction), $info-max-width);

          .name {
            font-size: call(get-function($scaleFunction), $name-font-size);
            line-height: call(get-function($scaleFunction), $line-height-lg);
          }

          .description {
            p {
              font-size: call(
                get-function($scaleFunction),
                $description-font-size
              );
              margin-top: call(get-function($scaleFunction), $text-margin-top);
              line-height: call(get-function($scaleFunction), $line-height-sm);
            }

            .subtitle {
              font-size: call(
                get-function($scaleFunction),
                $category-font-size
              );
              margin-top: call(
                get-function($scaleFunction),
                $category-margin-top
              );
              line-height: call(get-function($scaleFunction), $line-height-md);
            }
          }

          .address {
            .separator {
              margin: call(get-function($scaleFunction), $separator-margin);
            }
          }
        }

        .distanceInfo {
          border-radius: call(
            get-function($scaleFunction),
            $distance-border-radius
          );
          font-size: call(get-function($scaleFunction), $distance-font-size);
          line-height: call(get-function($scaleFunction), $line-height-lg);
          padding: call(get-function($scaleFunction), $distance-padding);
          gap: call(get-function($scaleFunction), $distance-gap);

          .icon {
            width: call(get-function($scaleFunction), $floor-icon-size);
            height: call(get-function($scaleFunction), $floor-icon-size);
          }
        }
      }
    }
  }
}

@mixin buildColors($color, $accent-bg, $closed, $accent-color) {
  &.container {
    .info {
      .name,
      .description,
      .address {
        color: $color;
      }

      .openingTimes {
        span {
          color: $color;
        }

        &.closed {
          span {
            color: $closed;
          }
        }

        &.tempClosed {
          color: $closed;
        }
      }
    }

    .distanceInfo {
      color: $accent-color;
      background: $accent-bg;
    }
  }
}

@include buildSizes();

.light {
  @include buildColors(
    $grey-tone-050,
    $blue-tone-600,
    $red-tone-600,
    $white-tone
  );
}

.dark {
  @include buildColors(
    $grey-tone-050,
    $blue-tone-200,
    $red-tone-200,
    $grey-tone-800
  );
}

.container {
  .content {
    text-shadow: 0px 0px 5px rgba($black-tone, 0.1);
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  p {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .info {
    z-index: 1;
    .name {
      @include font-title-small;
    }

    .description {
      .subtitle {
        @include font-label-small;
      }

      .address,
      .openingTimes {
        @include font-body-small;
      }
    }
  }

  .distanceInfo {
    color: $grey-tone-050;
    z-index: 1;
    display: flex;
    align-items: flex-start;
    width: fit-content;
    @include font-label-large;
    font-weight: 600;
    align-items: center;
    justify-content: center;

    .icon {
      &.floorUp {
        transform: rotate(-135deg);
      }

      &.floorDown {
        transform: rotate(-45deg);
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    height: 100%;
    left: 0;
    background: linear-gradient(
      90deg,
      rgba($black-tone, 0.4) 68.42%,
      rgba($black-tone, 0) 100%
    );
  }
}

@include scale-for-4k-media-query {
  @include buildSizes($doubled-sizes);
}
