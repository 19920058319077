@import "../../scss/newScaleFunctions.scss";
@import "../../scss/common.scss";

$control-left-gap: 30px;
$control-right-gap: 30px;
$control-between: 12px;

$search-height-padder: 480px;
$search-results-margin: 0 0 8px;

$quick-search-tags-container-height: 103px;

$attribution-share-container-padding: 0 0 10px;

$footer-buttons-margin-bottom: 9.75px;
$footer-button-gap-margin: 6px;

$logo-size: 6px;
$logo-margin-right: 2px;

$attribution-margin-left: 8px;
$attribution-font-size: 9px;

$toggle-bottom-margin: 16px;

$modal-body-margin-bottom: 20px;

$poi-content-height: 270px;

$single-poi-margin: 20px 112px 0;
$single-poi-accessible-margin: 0 112px 20px;

$carousel-margin: 20px 124px 0;
$carousel-accessible-margin: 0 124px 20px;

$search-tags-margin: 20px 112px 0;
$search-tags-accessible-margin: 0 112px 20px;

$control-gap: 40px;
$accessible-control-gap: 32px;

@mixin buildCompassSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      right: call(get-function($scaleFunction), $control-right-gap);
      top: call(get-function($scaleFunction), $control-right-gap);
    }
  }
}

@mixin buildControlSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    .controlContainer {
      &.#{$component-size} {
        .controlTopMargin {
          margin-top: call(get-function($scaleFunction), $control-between);
        }

        .controlRightMargin {
          margin-right: call(get-function($scaleFunction), $control-between);
        }
      }
    }

    .controlLeftContainer {
      &.#{$component-size} {
        left: call(get-function($scaleFunction), $control-left-gap);
      }
    }

    .controlRightContainer {
      &.#{$component-size} {
        right: call(get-function($scaleFunction), $control-right-gap);
      }
    }
  }
}

@mixin buildSearchSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    .searchHeightPadder {
      &.#{$component-size} {
        height: call(get-function($scaleFunction), $search-height-padder);
      }
    }

    .searchResultsContainer {
      &.#{$component-size} {
        margin: call(get-function($scaleFunction), $search-results-margin);
      }
    }
  }
}

@mixin buildSearchControlSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      .toggleContainer {
        > * {
          margin-bottom: call(
            get-function($scaleFunction),
            $toggle-bottom-margin
          );
        }
      }

      .poiContainer {
        .carousel {
          margin: call(get-function($scaleFunction), $carousel-margin);
          display: flex;
          justify-content: center;

          &.accessibleHeight {
            margin: call(
              get-function($scaleFunction),
              $carousel-accessible-margin
            );
          }
        }

        .singlePoi {
          margin: call(get-function($scaleFunction), $single-poi-margin);

          &.accessibleHeight {
            margin: call(
              get-function($scaleFunction),
              $single-poi-accessible-margin
            );
          }
        }
      }

      .searchTagsContainer {
        margin: call(get-function($scaleFunction), $search-tags-margin);

        &.accessibleHeight {
          margin: call(
            get-function($scaleFunction),
            $search-tags-accessible-margin
          );
        }
      }
    }
  }
}

@mixin buildFooterComponentSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      .quickSearchTagsContainer {
        height: call(
          get-function($scaleFunction),
          $quick-search-tags-container-height
        );
      }

      .attributionShareContainer {
        padding: call(
          get-function($scaleFunction),
          $attribution-share-container-padding
        );

        .content {
          button {
            margin-bottom: call(
              get-function($scaleFunction),
              $footer-buttons-margin-bottom
            );

            $x: call(get-function($scaleFunction), $footer-button-gap-margin);

            &.shareButton.showLowerControls:not(.alwaysLowControls) {
              transform: translateX(calc(-100% - #{$x}));
            }

            &.moveControlsButton {
              left: $x;
            }
          }

          .attribution {
            font-size: call(
              get-function($scaleFunction),
              $attribution-font-size
            );

            .logo {
              height: call(get-function($scaleFunction), $logo-size);
              width: call(get-function($scaleFunction), $logo-size);
              margin-right: call(
                get-function($scaleFunction),
                $logo-margin-right
              );
            }

            span:last-child {
              margin-left: call(
                get-function($scaleFunction),
                $attribution-margin-left
              );
            }
          }
        }
      }
    }
  }
}

@mixin buildModalBodySizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    .#{$component-size} {
      &.modalBody {
        margin-bottom: call(
          get-function($scaleFunction),
          $modal-body-margin-bottom
        );
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .primaryMap {
    position: relative;
    flex: 1;
    z-index: 2;

    .primaryCompassContainer {
      position: absolute;

      @include buildCompassSizes();
    }

    .mapControlsContainer {
      position: relative;
    }

    .controlContainer {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      pointer-events: all;
      z-index: 1;
      bottom: $control-gap + $poi-content-height;

      &.accessibleHeight {
        bottom: $accessible-control-gap;
      }

      &.controlLeftContainer {
        > div {
          display: flex;
          align-items: center;
        }
      }
    }

    .diagnosticsBody {
      white-space: pre-wrap;
      text-align: left;
    }

    @include buildControlSizes();
    @include buildModalBodySizes();
  }

  .searchWrapper {
    &.accessibleHeight {
      .animatedWrapper {
        transform: translateY(0);
      }
    }

    .animatedWrapper {
      transform: translateY(21%);
    }

    .searchHeightPadder {
      display: flex;
      align-items: flex-end;
    }

    .searchResultsContainer {
      z-index: 3;
    }

    @include buildSearchSizes();
  }

  .searchControlsContainer {
    position: absolute;
    pointer-events: none;
    z-index: 1;
    bottom: 0;
    width: 100%;
    margin: 0 auto;

    .searchFeaturesContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      width: 100%;
      bottom: $control-gap + $poi-content-height;

      &.accessibleHeight {
        bottom: $control-gap;

        .poiContainer,
        .searchTagsContainer {
          order: -1;
        }

        .searchTagsContainer {
          bottom: 100%;
          top: unset;
          margin-bottom: 20px;
        }
      }

      .toggleContainer {
        position: absolute;
        width: 100%;

        > * {
          margin: 0 auto;
          left: 0;
          right: 0;
          position: absolute;
          bottom: 100%;
          pointer-events: all;
        }
      }

      .hidden {
        visibility: hidden;
        z-index: -1;
      }

      .searchContainer {
        pointer-events: none;
        margin: 0 auto;

        button {
          pointer-events: all;
        }
      }

      .poiContainer {
        width: 100%;

        .carousel,
        .singlePoi {
          pointer-events: all;
          position: absolute;
          left: 0;
          right: 0;

          &.accessibleHeight {
            position: relative;
          }
        }
      }

      .searchTagsContainer {
        pointer-events: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
      }
    }

    @include buildSearchControlSizes();
  }

  .footerContainer {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
    flex-direction: column;
    pointer-events: none;

    .quickSearchTagsContainer {
      pointer-events: none;
      background-image: linear-gradient(#ffffff00, #ffffffe5);

      button {
        pointer-events: all;
      }
    }

    .attributionShareContainer {
      pointer-events: all;
      width: 100%;
      color: #212121;
      font-family: "IBM Plex Sans";
      background: #ffffffe5;

      .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .buttons {
          display: flex;
          flex-direction: row;

          &.showLowerControls {
            width: 100%;
            transform: translateX(50%);
          }

          .alwaysLowControls {
            transform: translateX(-50%);
          }

          .moveControlsButton {
            position: absolute;
          }
        }

        .attribution {
          font-weight: 450;
          letter-spacing: 0.06px;
          line-height: 12px;
          margin: 0 auto;
        }
      }
    }

    @include buildFooterComponentSizes();
  }
}

@include scale-for-4k-media-query {
  .container {
    .primaryMap {
      .primaryCompassContainer {
        @include buildCompassSizes($doubled-sizes);
      }

      @include buildControlSizes($doubled-sizes);
      @include buildModalBodySizes($doubled-sizes);
    }

    .mapControlsContainer {
      .controlContainer {
        bottom: scaleMediumDouble($control-gap + $poi-content-height);

        &.accessibleHeight {
          bottom: scaleMediumDouble($accessible-control-gap);
        }
      }
    }

    .searchControlsContainer {
      @include buildSearchControlSizes($doubled-sizes);

      .searchFeaturesContainer {
        bottom: scaleMediumDouble($control-gap + $poi-content-height);

        &.accessibleHeight {
          bottom: scaleMediumDouble($control-gap);
        }
      }
    }

    .searchWrapper {
      @include buildSearchSizes($doubled-sizes);
    }

    .footerContainer {
      @include buildFooterComponentSizes($doubled-sizes);
    }
  }
}
