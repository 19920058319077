@import "../../scss/common.scss";

$container-gap: px-to-rem(12px);
$row-gap: px-to-rem(8px);

.container {
  display: flex;
  flex-direction: column;
  gap: $container-gap;
  align-items: center;

  .row {
    display: flex;
    flex-wrap: nowrap;
    gap: $row-gap;

    > button {
      pointer-events: all;
    }
  }
}
